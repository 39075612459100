
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { setDepositFormat } from '../helpers/localstorage';
import { API_CONFIG } from '../serverConfig'
export const setOpen = (open, form, adress) =>  dispatch => {
	// dispatch(setAdress(adress));
	dispatch(setOpenDialog({open: open, form: form, type: adress}));
}
export const changeAdres = (adress) =>  dispatch => {
	// dispatch(setAdress(adress));
}
export const setStep = (step) =>  dispatch => {
	dispatch(setSteps(step))
}
export const cleanFicalData = () =>  dispatch => {
	dispatch(setFiscalData(null))
}
export const setTitle = (title) =>  dispatch => {
	dispatch(setTitles(title))
}
export const setOrderShippingId = (e) =>  dispatch => {
	dispatch(setOrderShipping(e))
}
export const setStoreCart = (e) =>  dispatch => {
	dispatch(setCart(e))
}
export const getCountries = () => async (dispatch, getState) => {
	try {
		const response =  await axios.get(`${API_CONFIG.API_URL}dropdowns/countries/all`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`}})
		dispatch(setCountries(response.data))
		return response
	}	
	catch (error) {
		return false
	}
}
export const getPersonAddress = (personId) => async (dispatch, getState) => {
	try {
		const response =  await axios.get(`${API_CONFIG.API_URL}order-shipping/person/${personId}`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`}})
		dispatch(setPersonAddress(response))
		return response
	}	
	catch (error) {
		return false
	}
}
export const getStudentsToPayment = (personId) => async (dispatch, getState) => {
	try {
		const response =  await axios.get(`${API_CONFIG.API_URL}persons/${personId}/student-list`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`}})
		dispatch(setStudentsToPayment(response.data))
		return response
	}	
	catch (error) {
		return false
	}
}
export const getPaymentInstallmentBySchool = (schoolId) => async (dispatch, getState) => {
	try {
		let response = await axios.get(`${API_CONFIG.API_URL}dropdowns/payment-installment/school/${schoolId}`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`}})
		dispatch(setPaymentInstallmentBySchool(response.data))	
		return true;
	}
	catch (error) {
		console.log('getPaymentInstallmentBySchool', error)
		return false
	}
}
export const searchRFC = (rfc) => async (dispatch, getState) => {
	try {
		const response =  await axios.get(`${API_CONFIG.API_URL}invoices/fiscal-data?rfc=${rfc}`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`}})
		dispatch(setFiscalData(response.data))
		return response.data
	}	
	catch (error) {
		dispatch(setFiscalData(false))
		return false
	}
}
export const getMexicoPostalCodes = (postalCode) => async (dispatch, getState) => {
	try {
		const response = await axios({
			method: 'POST',
			url: `${API_CONFIG.APP_API_FUNCTIONS_URL}mexicoPostalCodes`,
			data: {
				postalCode: `${postalCode}`,
			},
		});
		dispatch(setMexicoPostalCodes(response.data))
		return response.data
	} catch (error) {
		dispatch(setMexicoPostalCodes([]))
		return false
	}
}
export const getMexicoPostalCode = (postalCode, countryCode) => async (dispatch, getState) => {
	try {
		const response =  await axios.get(`${API_CONFIG.API_URL}postal-code/${countryCode}`, { params: { postalCode: postalCode, } });
		dispatch(setMexicoPostalCode(response.data))
		return response.data
	} catch (error) {
		console.error('getPostalCodes', error);
		return false
	}
}
export const getProducts = () => async (dispatch, getState) => {
	try {
		const response =  await axios.get(`${API_CONFIG.API_URL}online-store/products`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`}})
		dispatch(setProducts(response.data))
		return true
	}	
	catch (error) {
		return false
	}
}
export const getCategories = () => async (dispatch, getState) => {
	try {
		const response =  await axios.get(`${API_CONFIG.API_URL}product-categories`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`}})
		dispatch(setCategories(response.data))
		return true
	}	
	catch (error) {
		return false
	}
}
export const getAcademicYearsPaying = (countryCode) => async (dispatch, getState) => {
	try {
		const response =  await axios.get(`${API_CONFIG.API_URL}countries/${countryCode}/academic-years-paying`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`}})
		dispatch(setAcademicYearsPaying(response.data))
		return response
	}	
	catch (error) {
		return false
	}
}
export const postFiscalData = (body) => async (dispatch, getState) => {
	try {
		await axios.post(`${API_CONFIG.API_URL}invoices/fiscal-data`, body, { headers: { 'x-app-id': `${API_CONFIG.APP_ID}`}} )
		return true
	}
	catch (error) {
		console.log('postFiscalData', error)
		return false
	}
}
export const putFiscalData = (body) => async (dispatch, getState) => {
	try {
		await axios.put(`${API_CONFIG.API_URL}invoices/fiscal-data`, body, { headers: { 'x-app-id': `${API_CONFIG.APP_ID}`}} )
		return true
	}
	catch (error) {
		console.log('putFiscalData', error)
		return false
	}
}
export const createDepositFormat = (body) => async (dispatch, getState) => {
	try {
		let response = await axios.post(`${API_CONFIG.API_URL}payments/cie-slip`, body, { headers: { 'x-app-id': `${API_CONFIG.APP_ID}`}} )
		setDepositFormat(response.data)
		dispatch(setDeposit(response.data))
		return response
	}
	catch (error) {
		console.log('postFiscalData', error)
		return false
	}
}
export const createSantanderUrl = (body) => async (dispatch, getState) => {
	try {
		let response = await axios.post(`${API_CONFIG.API_URL}payments/webpay-link`, body, { headers: { 'x-app-id': `${API_CONFIG.APP_ID}`}} )
		setDepositFormat(response.data)
		dispatch(setDeposit(response.data))
		return response
	}
	catch (error) {
		console.log('postFiscalData', error)
		return false
	}
}
export const postDirectionData = (body) => async (dispatch, getState) => {
	try {
		let response = await axios.post(`${API_CONFIG.API_URL}order-shipping`, body, { headers: { 'x-app-id': `${API_CONFIG.APP_ID}`}} )
		dispatch(setAdress(response.data))
		return response
	}
	catch (error) {
		console.log('postFiscalData', error)
		return false
	}
}
export const putDirectionData = (body, orderShippingId) => async (dispatch, getState) => {
	try {
		await axios.put(`${API_CONFIG.API_URL}order-shipping/${orderShippingId}`, body, { headers: { 'x-app-id': `${API_CONFIG.APP_ID}`}} )
		return true
	}
	catch (error) {
		console.log('putFiscalData', error)
		return false
	}
}


const shopAdapter = createEntityAdapter({});

export const { selectAll: selectEvents, selectById: selectEventsById } = shopAdapter.getSelectors(
	state => state.App.shop
);

const shopSlice = createSlice({
	name: 'App/shop',
	initialState:shopAdapter.getInitialState({
		products: null,
		categories: null,
		step: 1,
		fiscalData: null,
		open: false,
		orderShipping: null,
		personAddress: [],
		postalCode: [],
		postalCodes: [],
		payByScholl: [],
		academicYearsPaying: [],
		depositFormat: null,
		adress: null,
		studentToPayment: [],
		countries: [],
		cart: null,
		title: ''
	}),
	reducers: {
		setPaymentInstallmentBySchool: (state, action) => {
			state.payByScholl = action.payload;
		},
		setMexicoPostalCode: (state, action) => {
			state.postalCode = action.payload
		},
		setStudentsToPayment: (state, action) => {
			state.studentToPayment = action.payload
		},
		setMexicoPostalCodes: (state, action) => {
			state.postalCodes = action.payload
		},
		setOrderShipping: (state, action) => {
			state.orderShipping = action.payload
		},
		setFiscalData: (state, action) => {
			state.fiscalData = action.payload
		},
		setAcademicYearsPaying: (state, action) => {
			state.academicYearsPaying = action.payload
		},
		setProducts: (state, action) => {
			state.products = action.payload
		},
		setCategories: (state, action) => {
			state.categories = action.payload
		},
		setAdress: (state, action) => {
			state.adress = action.payload
		},
		setOpenDialog: (state, action) => {
			state.open = action.payload
		},
		setPersonAddress: (state, action) => {
			state.personAddress = action.payload
		},
		setCountries: (state, action) => {
			state.countries = action.payload
		},
		setSteps: (state, action) => {
			state.step = action.payload
		},
		setDeposit: (state, action) => {
			state.depositFormat = action.payload
		},
		setTitles: (state, action) => {
			state.title = action.payload;
		},
		setCart: (state, action) => {
			state.cart = action.payload;
		},
	},
	extraReducers: {}
});

export const { setTitles, setCart, setProducts, setSteps, setFiscalData, setOpenDialog, setCategories, setPersonAddress, setCountries, setMexicoPostalCodes, setOrderShipping, setMexicoPostalCode, setPaymentInstallmentBySchool, setAdress, setAcademicYearsPaying, setDeposit, setStudentsToPayment} = shopSlice.actions;

export default shopSlice.reducer;
